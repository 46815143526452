import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import request from '../utils/request';
import { setAuthData, selectAuthdata } from '../slices/authSlice'

function Header() {
    const authInfo = useSelector(selectAuthdata);
    const [userType, setUserType] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/login', { replace: true });
        
    }

    useEffect(() => {
        fetchLogedUserInfo();
    }, [])

    const fetchLogedUserInfo = () => {
        let userData = localStorage.getItem('userData');
        if(userData){
            dispatch(setAuthData({ authData: JSON.parse(userData) }))
        }
       
    }
    const toggleSidebar = () => {
        const body = document.body;
        let classNameToAddOrRemove = 'toggle-sidebar';
        if (body.classList.contains(classNameToAddOrRemove)) {
            // If it's present, remove the class
            body.classList.remove(classNameToAddOrRemove);
        } else {
            // If it's not present, add the class
            body.classList.add(classNameToAddOrRemove);
        }
    }
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center">
                    <img style={{'width':'52px'}} src="/assets/img/logo.jpg" alt="" />
                    {/* <span className="d-none d-lg-block">FGCoinAdmin</span> */}

                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
            </div>
            {/* 
            <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                </form>
            </div> */}

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>


                    <li className="nav-item dropdown pe-3">

                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <img src="/assets/img/logo.jpg" alt="Profile" className="rounded-circle" />
                            <span className="d-none d-md-block dropdown-toggle ps-2">{authInfo?.name}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{authInfo?.user?.username}</h6>
                                <span>{authInfo?.user?.email}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            {userType == 'admin' && <>
                                <li>
                                    <Link to="/admin/update-profile" className="dropdown-item d-flex align-items-center">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/admin/update-password">
                                        <i className="bi bi-gear"></i>
                                        <span>Update Password</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                            </>}
                            {userType == 'user' && <>
                                <li>
                                    <Link to="/user/update-profile" className="dropdown-item d-flex align-items-center">
                                        <i className="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to="/user/update-password">
                                        <i className="bi bi-gear"></i>
                                        <span>Update Password</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                            </>}




                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" onClick={logout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Sign Out</span>
                                </a>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>
    )
}

export default Header