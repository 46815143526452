import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
    let userDetails =JSON.parse(localStorage.getItem('userData'));
    console.log(userDetails);
    let permissionList =[];
    if(userDetails.user.roles){
        let permissionListObj=[];
        userDetails.user.roles.forEach(role=>{
             permissionListObj.push(...role.privileges)
        });
        console.log(permissionListObj)
        permissionListObj.forEach(element => {
            permissionList.push(element.name)
        });
    }
    console.log(permissionList);
    const toggleSidebar = () => {
        const body = document.body;
        let classNameToAddOrRemove = 'toggle-sidebar';
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            
            if (body.classList.contains(classNameToAddOrRemove)) {
                // If it's present, remove the class
                body.classList.remove(classNameToAddOrRemove);
            } else {
                // If it's not present, add the class
                body.classList.add(classNameToAddOrRemove);
            }
          }
    }
    return (

        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className="nav-link " to="/admin/dashboard" onClick={toggleSidebar}>
                        <i className="bi bi-grid"></i>
                        <span style={{'color':'#012970'}}>Dashboard</span>
                    </Link>
                </li>
             

                {(permissionList.includes('designation-read') || permissionList.includes('designation-write')  || permissionList.includes('camloc-read') || permissionList.includes('camloc-delete') || permissionList.includes('camloc-edit') || permissionList.includes('offloc-edit')|| permissionList.includes('offloc-delete')|| permissionList.includes('offloc-read') || permissionList.includes('designation-delete') || permissionList.includes('designation-edit') ) &&<li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav1" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"  style={{'color':'#31982e'}}></i><span>Master</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav1" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        {(permissionList.includes('designation-read') || permissionList.includes('designation-delete') || permissionList.includes('designation-edit') ||  permissionList.includes('designation-write'))&&   <li>
                            <Link to="/admin/designation-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Designation</span>
                            </Link>
                        </li>}
                      
                        {(permissionList.includes('offloc-read') || permissionList.includes('offloc-edit')|| permissionList.includes('offloc-delete') ||  permissionList.includes('offloc-write'))&&  <li>
                            <Link to="/admin/office-location-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Office Location</span>
                            </Link>
                        </li>}
                        {(permissionList.includes('camloc-read') || permissionList.includes('camloc-delete') || permissionList.includes('camloc-edit') || permissionList.includes('camloc-write')) && <li>
                            <Link to="/admin/camera-location-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Camera Location</span>
                            </Link>
                        </li>}




                    </ul>
                </li>}
             
                {(permissionList.includes('auth-read')||permissionList.includes('auth-write')|| permissionList.includes('auth-edit') || permissionList.includes('auth-delete')) && <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav3" data-bs-toggle="collapse" href="#">
                        <i className="bx bx-user-circle"  style={{'color':'#31982e'}}></i><span>Users</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav3" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                       {(permissionList.includes('auth-read') || permissionList.includes('auth-edit') || permissionList.includes('auth-delete')) && <li>
                            <Link to="/admin/user-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Users List</span>
                            </Link>
                        </li>} 
                       {permissionList.includes('auth-write') && <li onClick={()=>window.location.reload()}>
                            <Link to="/admin/user-create" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Users</span>
                            </Link>
                        </li>} 

                    </ul>
                </li>}
                {(permissionList.includes('office-read')|| permissionList.includes('office-write') || permissionList.includes('office-edit')|| permissionList.includes('office-delete')) &&<li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav4" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-building"  style={{'color':'#31982e'}}></i><span>Offices</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav4" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        {(permissionList.includes('office-read') || permissionList.includes('office-edit')||permissionList.includes('office-delete')) &&<li>
                            <Link to="/admin/office-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Office List</span>
                            </Link>
                        </li>}
                        {permissionList.includes('office-write')  &&<li onClick={()=>window.location.reload()}>
                            <Link to="/admin/office-create" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Office</span>
                            </Link>
                        </li>}
                    </ul>
                </li>}
                {(permissionList.includes('camera-read')|| permissionList.includes('camera-write') || permissionList.includes('camera-delete') || permissionList.includes('camera-edit') )&&<li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav5" data-bs-toggle="collapse" href="#">
                        <i className="bx bx-camera"  style={{'color':'#31982e'}}></i><span>Camera</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav5" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                       {( permissionList.includes('camera-write')) && <li>
                            <Link to="/admin/camera-create" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Add Camera</span>
                            </Link>
                        </li>} 
                      
                       {(permissionList.includes('camera-read') || permissionList.includes('camera-delete') || permissionList.includes('camera-edit'))&& <li>
                            <Link to="/admin/camera-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Camera list</span>
                            </Link>
                        </li>} 

                    </ul>
                </li>}

                {(permissionList.includes('detect-read') || permissionList.includes('detect-view-image-video') || permissionList.includes('camera-detection')) &&<li className="nav-item">
                    <Link className="nav-link " to="/admin/camera-detection" onClick={toggleSidebar}>
                        <i className="bx bx-camera"></i>
                        <span style={{'color':'#012970'}}>Camera Detection</span>
                    </Link>
                </li>}

                {(permissionList.includes('rp-read')||permissionList.includes('rp-write') || permissionList.includes('rp-edit') ||permissionList.includes('rp-delete')) && <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav7" data-bs-toggle="collapse" href="#">
                        <i className="bx bxl-microsoft-teams"  style={{'color':'#31982e'}}></i><span>Roles</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav7" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                    {(permissionList.includes('rp-read') || permissionList.includes('rp-edit') || permissionList.includes('rp-delete')) &&<li>
                            <Link to="/admin/roles-list" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Role List</span>
                            </Link>
                        </li>}
                        {permissionList.includes('rp-write') &&<li>
                            <Link to="/admin/roles-create" onClick={toggleSidebar}>
                                <i className="bi bi-circle"></i><span>Create Role</span>
                            </Link>
                        </li>}
                      

                    </ul>
                </li>}
             

            </ul>

        </aside>

    )
}

export default Sidebar