import { useRoutes } from 'react-router-dom';
import routes from './routes/router';
function App() {
  
 if(localStorage.getItem('userData')=='undefined'){
   localStorage.clear();
 }
var userData = JSON.parse(localStorage.getItem('userData'));
const tokenValue = localStorage.getItem('userData') ? userData.authToken  : '';
const routing = useRoutes(routes(tokenValue));
  return (
    <div>
     {routing}
    </div>
  );
}

export default App;
